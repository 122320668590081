import { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { getFromDB, getFromDBandFile, setToDB } from 'src/utils/cache/idbUtility';
import { useSettingsContext } from './useUserPrefs';



function useCache<T>(key: string,  externalValue?: T): [T, React.Dispatch<React.SetStateAction<T | any[]>>] {
  const [state, setState] = useState<T>(externalValue);
  const [settings] = useSettingsContext();
  const useFileCache = settings?.UseFileCache ?? true;
  // Load initial value from cache on mount
  useEffect(() => {
    const fetchFromCache = async () => {
      const cachedValue = await getFromDBandFile<T>(key,useFileCache);
      if (cachedValue !== undefined && cachedValue !== null) {
        // console.log("GETTING FROM CACHE ",key, cachedValue)
        setState(cachedValue);
      }
    };
    fetchFromCache();
  }, [key]);

  // Logic to update state with externalValue if different
  useEffect(() => {
    if (externalValue && !_.isEqual(externalValue, state)) {
      setState(externalValue);
    }
  }, [externalValue]);

  // Logic to check if state is different from the cached value and update the cache
  useEffect(() => {
    const updateCache = async () => {
      const cachedValue = await getFromDBandFile<T>(key,useFileCache);
      if (!_.isEqual(cachedValue, state)) {
        if(state){
          setToDB(key, state);
        }
        // console.log("Setting new cache ",key, state, "cached value:", cachedValue)
        
      }
    };
    updateCache();
  }, [key, state]);

  const customSetState = (newValue: T | any[]) => {
    if (Array.isArray(newValue)) {
      setState(prevState => ([...newValue] as unknown as T));
    } else if (typeof newValue === 'object' && newValue !== null) {
      setState(prevState => ({ ...prevState, ...newValue } as unknown as T));
    } else {
      setState(newValue);
    }
  };

  return [state, customSetState];
}

export default useCache;
